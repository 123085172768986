import AOS from "aos"
import { get, merge } from "lodash"
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react"
import { Helmet } from "react-helmet"
import styled, { ThemeProvider } from "styled-components"
import "../../../node_modules/aos/dist/aos.css"
import "../../../node_modules/slick-carousel/slick/slick-theme.css"
import "../../../node_modules/slick-carousel/slick/slick.css"
import GlobalContext from "../../context/GlobalContext"
import faviconAppleTouch from "../../images/favicon/apple-touch-icon.png"
import favicon16 from "../../images/favicon/favicon-16x16.png"
import favicon32 from "../../images/favicon/favicon-32x32.png"
// import siteManifest from "../../images/favicon/site.webmanifest"
// the full theme object
import { theme as baseTheme } from "../../utils"
import GlobalStyle from "../../utils/globalStyle"
import Footer from "../Footer"
import Header from "../Header"
import ModalVideo from "../ModalVideo"
import "./bootstrap-custom.scss"

const Loader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #fff;
  z-index: 9999999999;
  opacity: 1;
  visibility: visible;
  transition: all 1s ease-out 0.5s;
  &.inActive {
    opacity: 0;
    visibility: hidden;
  }
`

// options for different color modes
const modes = { light: "light", dark: "dark" }

// merge the color mode with the base theme
// to create a new theme object
const getTheme = mode =>
  merge({}, baseTheme, {
    colors: get(baseTheme.colors.modes, mode, baseTheme.colors),
  })

const Layout = ({ children, pageContext }) => {
  const gContext = useContext(GlobalContext)

  const [visibleLoader, setVisibleLoader] = useState(true)

  useLayoutEffect(() => {
    AOS.init()
    setVisibleLoader(false)
  }, [])

  // Navbar style based on scroll
  const eleRef = useRef()

  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.addEventListener(
        "popstate",
        function (event) {
          // The popstate event is fired each time when the current history entry changes.

          gContext.closeOffCanvas()
        },
        false
      )
      window.addEventListener(
        "pushState",
        function (event) {
          // The pushstate event is fired each time when the current history entry changes.
          gContext.closeOffCanvas()
        },
        false
      )
    }
  }, [gContext])

  if (pageContext.layout === "bare") {
    return (
      <ThemeProvider
        theme={
          gContext.themeDark ? getTheme(modes.dark) : getTheme(modes.light)
        }
      >
        <GlobalStyle showVerticalBackgroundImage={!!gContext.isMarketing} />
        <Helmet>
          <title>Factcil</title>
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href={faviconAppleTouch}
          />
          <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
          <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
          {/* <link rel="manifest" href={siteManifest} /> */}
        </Helmet>
        <Loader id="loading" className={visibleLoader ? "" : "inActive"}>
          <div className="load-circle">
            <span className="one"></span>
          </div>
        </Loader>
        <div className="site-wrapper overflow-hidden" ref={eleRef}>
          {children}
        </div>

        <ModalVideo />
      </ThemeProvider>
    )
  }

  return (
    <>
      <ThemeProvider
        theme={
          gContext.themeDark ? getTheme(modes.dark) : getTheme(modes.light)
        }
      >
        <GlobalStyle />
        <Helmet>
          <title>Factcil</title>
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href={faviconAppleTouch}
          />
          <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
          <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
          {/* <link rel="manifest" href={siteManifest} /> */}
        </Helmet>
        <Loader id="loading" className={visibleLoader ? "" : "inActive"}>
          <div className="load-circle">
            <span className="one"></span>
          </div>
        </Loader>
        <div className="site-wrapper overflow-hidden" ref={eleRef}>
          <Header isDark={gContext.headerDark} />
          {children}

          <Footer isDark={false} />
        </div>

        <ModalVideo />
      </ThemeProvider>
    </>
  )
}

export default Layout
