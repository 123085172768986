import { rgba } from "polished"
import { breakpoints } from "./breakpoints"

const defaultColors = {
  primary: "#002432",
  textHover: "#0080B2",
  secondary: "#5454d4",
  white: "#ffffff",
  dark: "#002432",
  black: "#000000",
  yellow: "#fedc5a",
  ash: "#413e65",
  green: "#93A141",
  lightGreen: "#ECEFD7",
  info: "#0e567c",
  success: "#77bf41",
  gray: "#73868E",
  linebreak: "#E4F0F4",
  info: "#0e567c",
  greyedOut: "#73868E",
  footerDark: "#002432",
  footerHighlight: "#2da0c4",
  lightBlue: "#DBF2FC",
  hoverBlue: "#0884b4",
  orange: "#fdb64d",
  navyBlue: "#00A9E0",
  simpleCardBackground: "#DFE1E9",
}

const colors = {
  primary: defaultColors.primary,
  secondary: defaultColors.secondary,
  textHover: defaultColors.textHover,
  light: defaultColors.white,
  lightShade: rgba(defaultColors.white, 0.75),
  dark: defaultColors.dark,
  darkShade: rgba(defaultColors.dark, 0.75),
  ash: defaultColors.ash,
  bg: defaultColors.white,
  border: rgba(defaultColors.ash, 0.115),
  shadow: rgba(defaultColors.ash, 0.175),
  heading: defaultColors.dark,
  text: rgba(defaultColors.dark, 0.75),
  warning: defaultColors.yellow,
  orange: defaultColors.orange,
  green: defaultColors.green,
  lightGreen: defaultColors.lightGreen,
  success: defaultColors.success,
  gray: defaultColors.gray,
  linebreak: defaultColors.linebreak,
  info: defaultColors.info,
  greyedOut: defaultColors.greyedOut,
  footerDark: defaultColors.footerDark,
  footerHighlight: defaultColors.footerHighlight,
  lightBlue: defaultColors.lightBlue,
  hoverBlue: defaultColors.hoverBlue,
  navyBlue: defaultColors.navyBlue,
  simpleCardBackground: defaultColors.simpleCardBackground,

  modes: {
    dark: {
      primary: defaultColors.primary,
      secondary: defaultColors.secondary,
      light: defaultColors.white,
      lightShade: rgba(defaultColors.white, 0.75),
      dark: defaultColors.dark,
      darkShade: rgba(defaultColors.dark, 0.75),
      ash: defaultColors.ash,
      bg: defaultColors.dark,
      border: rgba(defaultColors.ash, 0.115),
      shadow: rgba(defaultColors.ash, 0.175),
      heading: defaultColors.white,
      text: rgba(defaultColors.white, 0.75),
      warning: defaultColors.yellow,
      success: defaultColors.green,
      info: defaultColors.info,
    },
  },
}

const theme = {
  initialColorModeName: "light",
  colors: colors,
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  breakpoints: [
    `${breakpoints.sm}px`,
    `${breakpoints.md}px`,
    `${breakpoints.lg}px`,
    `${breakpoints.xl}px`,
  ],
}

export default theme
