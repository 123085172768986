export const menuItemsLeft = [
  {
    name: "",
    label: "Inicio",
    isExternal: false,
    isActive: true,
  },
  {
    name: "how-it-works",
    label: "¿Cómo funciona?",
    isExternal: false,
  },
  {
    name: "pricing",
    label: "Precios",
    isExternal: false,
  },
  {
    name: `blog`,
    label: "Blog",
    isExternal: false,
  },
]

export const menuItemsLeftInteractuar = [
  {
    name: "",
    label: "Inicio",
    isExternal: false,
  },
  {
    name: "how-it-works",
    label: "¿Cómo funciona?",
    isExternal: false,
  },
  {
    name: "pricing",
    label: "Precios",
    isExternal: false,
  },
  {
    name: `blog`,
    label: "Blog",
    isExternal: false,
  },
]

export const menuItemsRight = [
  {
    name: `${process.env.GATSBY_AIO_APP_URL}/login`,
    label: "Iniciar sesión",
    isExternal: true,
  },
]

export const menuItemsInteractuarRight = [
  {
    name: `${process.env.GATSBY_ON_DEMAND_APP_URL}/login?is_from_interactuar=true`,
    label: "Iniciar sesión",
    isExternal: true,
  },
]

export const menuItemMobile = [
  ...menuItemsRight,
  {
    name: `${process.env.GATSBY_AIO_APP_URL}/signup`,
    label: "Registrarme",
    isExternal: true,
  },
  ...menuItemsLeft,
]

export const menuItemInteractuarMobile = [
  ...menuItemsInteractuarRight,
  {
    name: `${process.env.GATSBY_ON_DEMAND_APP_URL}/signup/independiente?is_from_interactuar=true`,
    label: "Registrarme",
    isExternal: true,
  },
  ...menuItemsLeft,
]

export const menuItemMarketing = [
  {
    name: `https://wa.me/573012128715`,
    label: "Contáctanos",
    isExternal: true,
  },
  {
    name: `${process.env.GATSBY_AIO_APP_URL}/login`,
    label: "Iniciar sesión",
    isExternal: true,
  },
  {
    name: `${process.env.GATSBY_AIO_APP_URL}/signup`,
    label: "Regístrate",
    isExternal: true,
  },
]
