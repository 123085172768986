import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import { useLocation } from "@reach/router"
import { Link } from "gatsby"
import React, { useContext, useState } from "react"
import { Container } from "react-bootstrap"
import styled from "styled-components"
import GlobalContext from "../../context/GlobalContext"
import burguer from "../../images/svg/burguer.svg"
import { breakpoints, device } from "../../utils"
import moveScrollOffset from "../../utils/moveScrollOffset"
import { Button } from "../Core"
import Logo from "../Logo"
import NestedMenu from "../NestedMenu"
import Offcanvas from "../Offcanvas"
import {
  menuItemInteractuarMobile,
  menuItemMarketing,
  menuItemMobile,
  menuItemsInteractuarRight,
  menuItemsLeft,
  menuItemsLeftInteractuar,
  menuItemsRight,
} from "./menuItems"

const SiteHeader = styled.header`
  padding: 10px 0 10px 0;
  position: absolute !important;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  background-color: ${({ headerBlue }) =>
    headerBlue && "rgba(232,248,254,255)"};
  ${({ headerBlue, isMarketing }) =>
    headerBlue &&
    `&:before {
        content: "";
        width: 100% !important;
        height: ${isMarketing ? "100" : "135"}% !important;
        border: 0;
        position: absolute;
        z-index: -3;
        background-color: rgba(232,248,254,255);
        background-size: 100%;
      }`};

  @media ${device.md} {
    background: none;
    &:before {
      content: none;
    }
  }

  @media ${device.lg} {
    position: fixed !important;
    transition: background-color 0.4s ease-out;
    &.scrolling {
      background-color: "#ffffff";
    }
    &.reveal-header {
      transform: translateY(0%);
      z-index: 9999;
    }
  }

  .hvr-bounce-to-left {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    position: relative;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    z-index: 1;
  }

  .hvr-bounce-to-left:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 40px;

    transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;

    transition-duration: 300ms;
    padding: 11px 24px 13px;
  }

  .hvr-bounce-to-left:hover:before,
  .hvr-bounce-to-left:focus:before,
  .hvr-bounce-to-left:active:before {
    background: ${({ theme }) => theme.colors.textHover} !important;
    transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    box-shadow: 0px 0px 40px rgba(0, 36, 50, 0.05);
  }
  .btn-primary {
    font-size: 16px !important;
    width: 141px !important;
    height: 45px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.colors.primary} !important;
    padding: 12px 24px;
    border-radius: 40px;
    color: #fff !important;
    font-size: 21px;
    font-weight: 500;
    letter-spacing: -0.66px;
  }
`

const ToggleButton = styled.button`
  background-color: ${({ dark, theme }) =>
    dark ? theme.colors.lightShade : theme.colors.lightBlue} !important;
  border-radius: 18.75rem !important;
  height: 4rem;
  width: 3rem;
  color: ${({ dark, theme }) =>
    dark ? theme.colors.lightShade : theme.colors.darkShade}!important;
`

const Menu = styled.ul`
  @media ${device.lg} {
    display: flex;
    justify-content: flex-end;
  }
  .dropdown-toggle {
    cursor: pointer;
  }
  > li {
    > .nav-link {
      font-family: Ubuntu;
      @media ${device.lg} {
        color: ${({ theme, linkTextColor }) =>
          linkTextColor ? linkTextColor : theme.colors.primary} !important;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        padding-top: 18px !important;
        padding-bottom: 18px !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
      }
      @media (min-width: ${breakpoints.lg + 54}px) {
        padding-left: 12px !important;
        padding-right: 12px !important;
      }
      @media (min-width: ${breakpoints.lg + 108}px) {
        padding-left: 18px !important;
        padding-right: 18px !important;
      }
      &:hover,
      &.active {
        color: ${({ theme }) => theme.colors.textHover} !important;
      }
    }
  }
  .nav-item.dropdown {
    @media ${device.lg} {
      position: relative;
      z-index: 99;
    }
    &:hover {
      > .menu-dropdown {
        @media ${device.lg} {
          top: 90%;
          opacity: 1;
          pointer-events: visible;
        }
      }
    }
  }
`

const MenuDropdown = styled.ul`
  list-style: none;
  @media ${device.lg} {
    top: 110%;
    position: absolute;
    min-width: 227px;
    max-width: 227px;
    box-shadow: 0 52px 54px rgba(65, 62, 101, 0.3);
    border-radius: 8px;
    border: 1px solid #e5e5e5;
    background-color: #ffffff;
    padding: 15px 0px;
    z-index: 99;
    opacity: 0;
    transition: opacity 0.4s, top 0.4s;
    pointer-events: none;
    left: -90%;
    border-radius: 0 0 10px 10px;
    border: 1px solid #eae9f2;
    background-color: #ffffff;
    display: block;
    border-top: ${({ theme }) => `3px solid ${theme.colors.secondary}`};
  }
  > .drop-menu-item {
    color: ${({ theme }) => theme.colors.dark};
    font-size: 16px;
    font-weight: 300;
    letter-spacing: -0.5px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;

    a {
      color: ${({ theme }) => theme.colors.dark};
      transition: all 0.3s ease-out;
      position: relative;
      display: flex;
      align-items: center;
      &.dropdown-toggle::after {
        display: inline-block;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.325em solid;
        border-right: 0.325em solid transparent;
        border-bottom: 0;
        border-left: 0.325em solid transparent;
        position: relative;
        top: 1px;
        margin-left: auto;
        transform: rotate(-90deg);
        transition: 0.4s;
      }
    }

    &:hover {
      > a {
        color: ${({ theme }) => theme.colors.secondary};
        text-decoration: none;
        &::after {
          transform: rotate(0deg);
        }
      }
    }
    &.dropdown {
      position: relative;

      &:hover {
        > .menu-dropdown {
          @media ${device.lg} {
            top: 10px;
            opacity: 1;
            pointer-events: visible;
            transform: translateX(-100%);
          }
        }
      }
      > .menu-dropdown {
        border-top-color: ${({ theme }) => theme.colors.primary};
        @media ${device.lg} {
          top: 10px;
          left: 0%;
          opacity: 0;
          transform: translateX(-110%);
          transition: 0.4s;
          pointer-events: none;
        }
        > .drop-menu-item {
          @media ${device.lg} {
            padding-left: 30px;
            padding-right: 30px;
          }
        }
      }
    }
  }
  &.dropdown-right {
    left: auto;
    right: -90%;
  }
`

const LogoBox = styled.div`
  flex-grow: 0;
  text-align: ${({ isMarketing }) => (isMarketing ? "left" : "center")};
  @media ${device.lg} {
    flex-grow: 1;
  }
  @media ${device.xl} {
    margin-right: 7rem;
  }
  @media (min-width: ${breakpoints.lg + 75}px) {
    margin-right: 3rem;
    margin-left: 6.25rem;
  }
  @media ${device.sm} {
    margin-left: 4rem;
  }
  @media (min-width: ${breakpoints.xl + 300}px) {
    // margin-right: 19rem;
  }
`

const Header = ({ isDark = false }) => {
  const gContext = useContext(GlobalContext)
  const [showScrolling, setShowScrolling] = useState(false)
  const [showReveal, setShowReveal] = useState(false)
  const location = useLocation()

  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y < 0) {
      setShowScrolling(true)
    } else {
      setShowScrolling(false)
    }
    if (currPos.y < -300) {
      setShowReveal(true)
    } else {
      setShowReveal(false)
    }
  })

  const isSectionActive = sectionName => {
    return (
      (location.hash === sectionName && location.pathname === `/`) ||
      (location.pathname !== `/` && location.pathname === `/${sectionName}`)
    )
  }

  const isLeftMenuInteractuar = () => {
    if (gContext.isInteractuar) {
      return menuItemsLeftInteractuar
    }
    return menuItemsLeft
  }

  const getMenu = (IsLeftMenu, menuItems) => {
    let classes = "navbar-nav mr-3"
    if (!IsLeftMenu) {
      classes += "navbar-nav ml-lg-auto"
    }
    return (
      <div
        className="collapse navbar-collapse"
        style={{
          flexGrow: 0,
        }}
      >
        <div className={classes}>
          <Menu className="navbar-nav d-none d-lg-flex" dark={isDark ? 1 : 0}>
            {!gContext.isMarketing &&
              menuItems.map(
                (
                  { label, isExternal = false, name, items, ...rest },
                  index
                ) => {
                  const hasSubItems = Array.isArray(items)
                  return (
                    <React.Fragment key={name + index}>
                      {hasSubItems ? (
                        <li className="nav-item dropdown" {...rest}>
                          <a
                            className="nav-link dropdown-toggle"
                            role="button"
                            data-toggle="dropdown"
                            aria-expanded="false"
                            href="/#"
                            onClick={e => e.preventDefault()}
                          >
                            {label}
                          </a>
                          <MenuDropdown
                            className="menu-dropdown dropdown-right"
                            dark={isDark ? 1 : 0}
                          >
                            {items.map((subItem, indexSub) => {
                              const hasInnerSubItems = Array.isArray(
                                subItem.items
                              )
                              return (
                                <React.Fragment key={subItem.name + indexSub}>
                                  {hasInnerSubItems ? (
                                    <li className="drop-menu-item dropdown">
                                      <a
                                        className="dropdown-toggle"
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-expanded="false"
                                        href="/#"
                                        onClick={e => e.preventDefault()}
                                      >
                                        {subItem.label}
                                      </a>
                                      <MenuDropdown
                                        className="menu-dropdown dropdown-right"
                                        dark={isDark ? 1 : 0}
                                      >
                                        {subItem.items.map(
                                          (itemInner, indexInnerMost) => (
                                            <li
                                              className="drop-menu-item"
                                              key={
                                                itemInner.name + indexInnerMost
                                              }
                                            >
                                              {itemInner.isExternal ? (
                                                <a
                                                  href={`${itemInner.name}`}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  {itemInner.label}
                                                </a>
                                              ) : (
                                                <Link to={`/${itemInner.name}`}>
                                                  {itemInner.label}
                                                </Link>
                                              )}
                                            </li>
                                          )
                                        )}
                                      </MenuDropdown>
                                    </li>
                                  ) : (
                                    <li className="drop-menu-item">
                                      {subItem.isExternal ? (
                                        <a
                                          href={`${subItem.name}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {subItem.label}
                                        </a>
                                      ) : (
                                        <Link to={`/${subItem.name}`}>
                                          {subItem.label}
                                        </Link>
                                      )}
                                    </li>
                                  )}
                                </React.Fragment>
                              )
                            })}
                          </MenuDropdown>
                        </li>
                      ) : (
                        <li className="nav-item" {...rest}>
                          {isExternal ? (
                            <a
                              className="nav-link"
                              href={`${name}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {label}
                            </a>
                          ) : (
                            <Link
                              className={`nav-link ${
                                isSectionActive(name) ? "active" : ""
                              }`}
                              to={
                                gContext.isInteractuar
                                  ? `/interactuar/${name}`
                                  : `/${name}`
                              }
                              onClick={() => {
                                if (`${name}`.includes("#")) {
                                  moveScrollOffset(`${name}`)
                                }
                              }}
                              role="button"
                              aria-expanded="false"
                            >
                              {label}
                            </Link>
                          )}
                        </li>
                      )}
                    </React.Fragment>
                  )
                }
              )}
          </Menu>
        </div>
      </div>
    )
  }

  return (
    <>
      <SiteHeader
        className={`sticky-header ${showScrolling ? "scrolling" : ""} ${
          showReveal ? "reveal-header" : ""
        }`}
        dark={isDark ? 1 : 0}
        css={`
          ${gContext.headerBlue &&
          "background: linear-gradient(180deg, #E7F8FF 0%, rgba(231, 248, 255, 0.05) 100%);"}
        `}
        headerBlue={gContext.headerBlue}
        isMarketing={gContext.isMarketing}
      >
        <Container fluid>
          <nav className="navbar site-navbar offcanvas-active navbar-expand-lg navbar-light px-3">
            {!gContext.isMarketing && getMenu(true, isLeftMenuInteractuar())}

            <LogoBox className="brand-logo" isMarketing={gContext.isMarketing}>
              <Logo
                height={["28px", "28px", "30px", "32px"]}
                white={isDark}
                isInteractuar={gContext.isInteractuar}
                interactuarHeight={["64px", "64px", "90px", "103px"]}
                isMarketingPage={gContext.isMarketing}
              />
            </LogoBox>

            {gContext.isMarketing ? (
              getMenu(true, menuItemMarketing)
            ) : (
              <>
                {getMenu(
                  false,
                  gContext.isInteractuar
                    ? menuItemsInteractuarRight
                    : menuItemsRight
                )}
                <div className="header-btns ml-auto ml-lg-0 d-none d-md-block">
                  <a
                    href={`${process.env.GATSBY_AIO_APP_URL}/signup`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Button variant="factcilSolid">Registrarme</Button>
                  </a>
                </div>
              </>
            )}

            <ToggleButton
              className={`navbar-toggler btn-close-off-canvas ml-3 ${
                gContext.visibleOffCanvas ? "collapsed" : ""
              }`}
              type="button"
              data-toggle="collapse"
              data-target="#mobile-menu"
              aria-controls="mobile-menu"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={gContext.toggleOffCanvas}
              dark={isDark ? 1 : 0}
            >
              <img src={burguer}></img>
            </ToggleButton>
          </nav>
        </Container>
      </SiteHeader>
      <Offcanvas
        show={gContext.visibleOffCanvas}
        onHideOffcanvas={gContext.toggleOffCanvas}
        isInteractuar={gContext.isInteractuar}
      >
        <NestedMenu
          menuItems={
            gContext.isMarketing
              ? menuItemMarketing
              : gContext.isInteractuar
              ? menuItemInteractuarMobile
              : menuItemMobile
          }
        />
      </Offcanvas>
    </>
  )
}
export default Header
