import React from "react"
import styled from "styled-components"
import {
  background,
  border,
  color,
  flexbox,
  layout,
  shadow,
  space,
  typography,
} from "styled-system"

export const FactcilSolidButton = styled.button`
  border: none;
  font-family: Ubuntu;
  letter-spacing: 0.02em;
  line-height: 24px;
  font-style: normal;
  font-size: 14px !important;
  width: 141px !important;
  height: 45px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  border-radius: 40px;
  font-weight: 700;

  transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;

  transition-duration: 300ms;

  ${color};
  ${background};
  ${border};
  ${space};
  ${typography};
  ${shadow};
  ${flexbox};
  ${layout};

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 40px;

    transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;

    transition-duration: 300ms;
    padding: 11px 24px 13px;
  }

  &:hover,
  &:focus,
  &:active {
    background: ${({ theme }) => theme.colors.textHover};
    // background: red;
    transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    box-shadow: 0px 0px 40px rgba(0, 36, 50, 0.05);
  }
`

const FactcilSolidSecondaryButton = styled.button`
  font-family: Ubuntu;
  font-size: 16px !important;
  width: 200px !important;
  height: 45px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.light};
  padding: 12px 24px;
  border-radius: 40px;
  color: ${({ theme }) => theme.colors.primary}; !important;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: -0.66px;

  transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;

  transition-duration: 300ms;

  ${border};
  ${space};
  ${typography};
  ${shadow};
  ${flexbox};
  ${layout};

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 40px;

    transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;

    transition-duration: 300ms;
    padding: 11px 24px 13px;
  }

  &:hover,
  &:focus,
  &:active {
    color: ${({ theme }) => theme.colors.textHover}; 
    border-color: ${({ theme }) => theme.colors.textHover}; 
    // background: red;
    transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    box-shadow: 0px 0px 40px rgba(0, 36, 50, 0.05);
  }
`

const ButtonSolid = styled.button`
  font-family: Ubuntu;
  padding: 0.85rem 1.75rem;
  min-width: 200px;
  border-radius: 5px;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: -0.66px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transform: perspective(1px) translateZ(0);
  position: relative;
  overflow: hidden;
  outline: none !important;
  white-space: nowrap;
  ${color};
  ${background};
  ${border};
  ${space};
  ${typography};
  ${shadow};
  ${flexbox};
  ${layout};

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.1);
    transform: scaleX(0);
    transform-origin: 100% 50%;
    transition-property: transform;
    transition-duration: 0.5s;
    transition-timing-function: ease-out;
  }

  &:hover:before,
  &:focus:before,
  &:active:before {
    transform: scaleX(1);
    transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  }
`

const ButtonOutline = styled(ButtonSolid)`
  font-family: Ubuntu;
  background: transparent;

  &:before {
    background: ${({ theme, color }) => theme.colors[color]};
  }

  &:hover,
  &:focus,
  &:active {
    color: ${({ theme }) => theme.colors.dark};
  }
  &:hover:before,
  &:focus:before,
  &:active:before {
    transform: scaleX(1);
    transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    background: ${({ theme, color }) => theme.colors[color]};
  }
`

const Button = ({
  variant = "solid",
  color = "light",
  bg = "primary",
  ...rest
}) => {
  return variant === "solid" ? (
    <ButtonSolid
      color={color}
      border={`1px solid`}
      borderColor={bg}
      bg={bg}
      {...rest}
    />
  ) : (
    [
      variant === "factcilSolid" ? (
        <FactcilSolidButton
          key={"factcil-solid-btn"}
          color={color}
          bg={bg}
          {...rest}
        />
      ) : (
        [
          variant === "factcilSolidSecondary" ? (
            <FactcilSolidSecondaryButton
              key={"factcil-solid-second-btn"}
              color={color}
              border={`1px solid`}
              borderColor={bg}
              bg={bg}
              {...rest}
            />
          ) : (
            <ButtonOutline
              key={"button-outline"}
              color={color}
              bg={bg}
              border={`1px solid`}
              borderColor={color}
              {...rest}
            />
          ),
        ]
      ),
    ]
  )
}

export default Button
