export default function moveScrollOffset(elementId, timer = 10) {
  setTimeout(() => {
    var element = document.querySelector(`${elementId}`).getBoundingClientRect()
    var body = document.querySelector(`body`).getBoundingClientRect()
    var header = document.querySelector(`header`).getBoundingClientRect()
    if (element && body && header) {
      var headerOffset = header.height + 20
      var offsetPosition =
        body.height -
        body.bottom +
        element.bottom -
        element.height -
        headerOffset
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      })
    }
  }, timer)
}
