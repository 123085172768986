import React, { useEffect, useState } from "react"
import { breakpoints } from "../utils"

const GlobalContext = React.createContext()

const GlobalProvider = ({ children }) => {
  const [themeDark, setThemeDark] = useState(false)
  const [videoModalVisible, setVideoModalVisible] = useState(false)
  const [visibleOffCanvas, setVisibleOffCanvas] = useState(false)
  const [headerDark, setHeaderDark] = useState(false)
  const [headerBlue, setHeaderBlue] = useState(false)
  const [footerDark, setFooterDark] = useState(true)
  const [cartTotal, setCartTotal] = useState(3)
  const [isInteractuar, setIsInteractuar] = useState(false)
  const [isMarketing, setIsMarketing] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const [referralCode, setReferralCode] = useState(null)
  const [userEmail, setUserEmail] = useState(null)
  const browserWindow = typeof window !== `undefined` ? window : false

  useEffect(() => {
    setReferralCode(
      new URLSearchParams(browserWindow.location.search).get("referralCode")
    )
  }, [browserWindow])

  useEffect(() => {
    setUserEmail(
      new URLSearchParams(browserWindow.location.search).get("payload")
    )
  }, [browserWindow])

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= breakpoints.sm) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    }
    window.addEventListener("resize", handleResize)
  }, [])

  useEffect(() => {
    const route = window.location.pathname
    if (route.includes("interactuar")) {
      setIsInteractuar(true)
    }
  }, [])

  useEffect(() => {
    const route = window.location.pathname
    if (route.includes("comienza-ahora") || route.includes("mas-informacion")) {
      setIsMarketing(true)
    }
  }, [])

  const toggleTheme = () => {
    setThemeDark(!themeDark)
  }

  const toggleVideoModal = () => {
    setVideoModalVisible(!videoModalVisible)
  }

  const toggleOffCanvas = () => {
    setVisibleOffCanvas(!visibleOffCanvas)
  }

  const closeOffCanvas = () => {
    setVisibleOffCanvas(false)
  }

  const goHeaderDark = () => {
    setHeaderDark(true)
  }
  const goHeaderBlue = () => {
    setHeaderBlue(true)
  }
  const goHeaderLight = () => {
    setHeaderDark(false)
  }

  const goFooterDark = () => {
    setFooterDark(true)
  }
  const goFooterLight = () => {
    setFooterDark(false)
  }

  const incCartTotal = () => {
    setCartTotal(cartTotal + 1)
  }
  const decCartTotal = () => {
    setCartTotal(cartTotal - 1)
  }

  return (
    <GlobalContext.Provider
      value={{
        themeDark,
        toggleTheme,
        headerDark,
        headerBlue,
        goHeaderDark,
        goHeaderBlue,
        goHeaderLight,
        footerDark,
        goFooterDark,
        goFooterLight,
        videoModalVisible,
        toggleVideoModal,
        visibleOffCanvas,
        toggleOffCanvas,
        closeOffCanvas,
        cartTotal,
        incCartTotal,
        decCartTotal,
        isInteractuar,
        isMobile,
        referralCode,
        userEmail,
        isMarketing,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalContext
export { GlobalProvider }

