import "bootstrap/dist/css/bootstrap.min.css"
import React from "react"
import Layout from "./src/components/Layout"
import { GlobalProvider } from "./src/context/GlobalContext"

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>
}

export const wrapRootElement = ({ element }) => (
  <GlobalProvider>{element}</GlobalProvider>
)

export const onClientEntry = async () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    await import(`intersection-observer`)
    console.log(`# IntersectionObserver is polyfilled!`)
  }
}

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (typeof window !== `undefined`) {
    if (location.hash && location.hash !== "") {
      const id = location.hash.slice(1)
      const yOffset = -60
      const element = document.getElementById(id)
      if (element) {
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset
        window.scrollTo({ top: y, behavior: "smooth" })
      }
    } else {
      window.scrollTo(0, 0)
    }
  }
  return false
}
