import { Link } from "gatsby"
import React, { useContext } from "react"
import { Col, Container, Row } from "react-bootstrap"
import styled from "styled-components"
import GlobalContext from "../../context/GlobalContext"
import FacebookHover from "../../images/svg/Facebook-hover.svg"
import Facebook from "../../images/svg/Facebook.svg"
import Heart from "../../images/svg/Heart.svg"
import InstagramHover from "../../images/svg/Instagram-hover.svg"
import Instagram from "../../images/svg/Instagram.svg"
import LinkedInHover from "../../images/svg/LinkedIn-hover.svg"
import LinkedIn from "../../images/svg/LinkedIn.svg"
import { device } from "../../utils"
import { Box, Title } from "../Core"
import Logo from "../Logo"

const TitleStyled = styled(Title)`
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 700;
  letter-spacing: 0.02rem;
  margin-bottom: 1.5rem;
  @media ${device.xs} {
    text-align: center;
  }
  @media ${device.sm} {
    text-align: left;
  }
`

const UlStyled = styled.ul`
  padding-left: 0;
  li {
    line-height: 1rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
    font-size: 0.875rem;
    @media ${device.xs} {
      text-align: center;
    }
    @media ${device.sm} {
      text-align: left;
    }
    a {
      color: ${({ theme, color }) => theme.colors[color]} !important;
      &:hover {
        text-decoration: underline !important;
        color: ${({ theme, color }) => theme.colors.footerHighlight} !important;
      }
    }
  }
`

const FooterUnderline = styled.div`
  border-top: ${({ dark, theme }) =>
    dark ? `1px solid #2f2f31 ` : `1px solid ${theme.colors.linebreak}`};

  padding: 15px 0;
  p {
    color: ${({ dark, theme }) =>
    dark ? theme.colors.lightShade : theme.colors.gray};
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
`

const CopyRightArea = styled.div`
  p {
    color: ${({ dark, theme }) =>
    dark ? theme.colors.lightShade : theme.colors.footerDark};
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin: 0;
  }
  @media ${device.xs} {
    text-align: center;
  }
  @media ${device.sm} {
    text-align: left;
  }
  @media ${device.xs} {
    margin-top: 2.5rem;
  }
  @media ${device.sm} {
    margin-top: 0;
  }
`

const SocialMediaBox = styled(Box)`
  display: flex;
  justify-content: center;
  a {
    background-color: ${({ dark, theme }) =>
    dark ? theme.colors.lightShade : theme.colors.lightBlue};
    border-radius: 18.75rem;
    height: 3rem;
    width: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover,
    &:focus,
    &:active {
      color: red !important;
      // ${({ theme }) => theme.colors.textHover}; !important;
      transition-timing-function: ease-in-out;
      -webkit-transition-timing-function: ease-in-out;
      box-shadow: 0px 0px 40px rgba(0, 36, 50, 0.05);
    }
  }
`
const hoverImagesList = {
  "#social-media-id-1": LinkedInHover,
  "#social-media-id-2": FacebookHover,
  "#social-media-id-3": InstagramHover,
}

const hoverImages = (e, image = false) => {
  if (image) {
    e.target.innerHTML = `<img src=${image} alt="" />`
  } else {
    e.target.innerHTML = `<img src=${hoverImagesList[e.target.id]} alt="" />`
  }
}

const SocialMediaItem = props => (
  <SocialMediaBox className="p-0 m-0 px-2 pl-md-0 pr-md-3">
    <a
      href={props.href}
      target="_blank"
      id={`#social-media-id-${props.id}`}
      onTouchStart={e => hoverImages(e, false)}
      onMouseEnter={e => hoverImages(e, false)}
      onMouseLeave={e => hoverImages(e, props.img)}
      onTouchEnd={e => hoverImages(e, props.img)}
    >
      <img src={props.img} alt="" />
    </a>
  </SocialMediaBox>
)

const whatsAppNumber = process.env.CUSTOMER_SUCCESS_WHATASAPP_NUMBER

const footerItems = {
  about_factcil: [
    // {
    //   id: 1,
    //   to: "/#",
    //   text: "Quiénes somos",
    // },
    // {
    //   id: 2,
    //   to: "/#",
    //   text: "Invierte en Factcil",
    // },
    {
      id: 3,
      to: "/faqs",
      text: "Preguntas frecuentes",
    },
    {
      id: 4,
      to: "https://blog.factcil.com/",
      text: "Blog",
    },
  ],
  contact_us: [
    {
      id: 1,
      to: "mailto:comercial@factcil.com",
      text: "comercial@factcil.com",
    },
    {
      id: 2,
      to: "tel:3158731305",
      text: `(+${whatsAppNumber.substring(0, 2)}) ${whatsAppNumber.substring(
        2,
        5
      )} ${whatsAppNumber.substring(5, 8)} ${whatsAppNumber.substring(8, 12)}`,
    },
  ],
  interest_links: [
    {
      id: 1,
      to: "/privacy-policy",
      text: "Políticas de privacidad",
    },
    {
      id: 2,
      to: "/tos",
      text: "Términos y condiciones",
    },
    {
      id: 3,
      to: `${process.env.GATSBY_AIO_APP_URL}/profile/referral`,
      text: "Referidos",
    },
    {
      id: 4,
      to: "https://factcil-aio-prod.s3.amazonaws.com/Politicas+de+cumplimiento.pdf",
      text: "Política SAGRILAFT",
    },
  ],
}

const socialMedia = [
  {
    id: 1,
    href: "https://www.linkedin.com/company/factcil/",
    img: LinkedIn,
  },
  {
    id: 2,
    href: "https://www.facebook.com/factcil/",
    img: Facebook,
  },
  {
    id: 3,
    href: "https://www.instagram.com/factcil/",
    img: Instagram,
  },
]

const Footer = ({ isDark = false }) => {
  const gContext = useContext(GlobalContext)

  const LiLink = ({ to, text, target = "_blank" }) => {
    return (
      <li>
        <Link
          to={to}
          className="link text-decoration-none cursor-pointer"
          target={target}
          role="button"
        >
          {text}
        </Link>
      </li>
    )
  }
  return (
    <>
      {/* <!-- Footer section --> */}
      <Box bg={isDark ? "dark" : "light"}>
        <Container>
          {!gContext.isMarketing && (
            <Box className="p-5 m-0">
              {/* Row with logo and categories */}
              <Row className="p-0 m-0">
                <Col
                  md={12}
                  lg={3}
                  xl={3}
                  className="mx-0 px-3  mb-4 mb-sm-4 mb-md-4 text-left "
                  css={`
                    @media (max-width: 556px) {
                      text-align: center;
                    }
                  `}
                >
                  <Logo
                    white={isDark}
                    height="1.5rem"
                    isInteractuar={gContext.isInteractuar}
                    interactuarHeight="5rem"
                  />
                </Col>
                <Col md={12} lg={9} xl={9} className="p-0 m-0">
                  <Row className="justify-content-between p-0 m-0">
                    <Col xs={12} sm={4} lg={4} xl={4}>
                      <Box className="p-0 m-0">
                        <TitleStyled
                          variant="card"
                          color={isDark ? "light" : "footerDark"}
                        >
                          Sobre{" "}
                          {gContext.isInteractuar ? "Nosotros" : "Factcil"}
                        </TitleStyled>
                        <UlStyled color={isDark ? "lightShade" : "footerDark"}>
                          {footerItems.about_factcil.map(item => (
                            <LiLink {...item} />
                          ))}
                        </UlStyled>
                      </Box>
                    </Col>
                    <Col xs={12} sm={4} lg={4} xl={4}>
                      <Box className="p-0 m-0">
                        <TitleStyled
                          variant="card"
                          color={isDark ? "light" : "footerDark"}
                        >
                          Contáctanos
                        </TitleStyled>
                        <UlStyled color={isDark ? "lightShade" : "footerDark"}>
                          {footerItems.contact_us.map(item => (
                            <LiLink {...item} />
                          ))}
                        </UlStyled>
                      </Box>
                    </Col>
                    <Col xs={12} sm={4} lg={4} xl={4}>
                      <Box className="p-0 m-0">
                        <TitleStyled
                          variant="card"
                          color={isDark ? "light" : "footerDark"}
                        >
                          Links de interés
                        </TitleStyled>
                        <UlStyled color={isDark ? "lightShade" : "footerDark"}>
                          {footerItems.interest_links.map(item => (
                            <LiLink {...item} />
                          ))}
                        </UlStyled>
                      </Box>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {/* Row with social and trademark */}
              <Row className="p-0 m-0 container-fluid">
                <Col xs={12} sm={5} lg={3} className="p-0 m-0">
                  <Box className="p-0 m-0 d-flex justify-content-center justify-content-md-start">
                    {socialMedia.map(social => (
                      <SocialMediaItem
                        id={social.id}
                        key={social.id}
                        href={social.href}
                        img={social.img}
                      />
                    ))}
                  </Box>
                </Col>
                <Col xs={12} sm={7} lg={9} className=" d-flex align-items-end">
                  <CopyRightArea
                    dark={isDark ? 1 : 0}
                    className="container-fluid p-0"
                  >
                    <p className="align-bottom">
                      &copy; {new Date().getFullYear()}{" "}
                      {`${gContext.isInteractuar ? "Interactuar y" : ""
                        } Factcil. Todos los
                    derechos reservados.`}
                    </p>
                  </CopyRightArea>
                </Col>
              </Row>
            </Box>
          )}
          <FooterUnderline dark={isDark ? 1 : 0}>
            <Row className="align-items-center">
              <Col xl={12} className="text-center">
                <p>
                  Hecho con <img src={Heart} alt="" />{" "}
                  {`${gContext.isInteractuar
                    ? "por Factcil"
                    : "para trabajadores independientes"
                    }`}
                </p>
              </Col>
            </Row>
          </FooterUnderline>
        </Container>
      </Box>
    </>
  )
}

export default Footer
