import { Link } from "gatsby"
import React, { useContext } from "react"
import GlobalContext from "../../context/GlobalContext"
import imgLogoMobile from "../../images/png/blue-factcil-elephant.png"
import { default as imgFactcilMarketingLogo } from "../../images/svg/factcil-logo-white.svg"
import { default as imgL1LogoWhite } from "../../images/svg/factcil-logo.svg"
import { default as imgInteractuarByFactcilLogo } from "../../images/svg/interactuar-by-factcil-logo.svg"
import { default as imgInteractuarByFactcilMobileLogo } from "../../images/svg/interactuar-by-factcil-mobile-logo.svg"
import { default as imgInteractuarLogo } from "../../images/svg/interactuar-logo.svg"
import { device } from "../../utils"

const Logo = ({
  white,
  height,
  className = "",
  isHeader,
  noLeftPadding,
  isMobile,
  isInteractuar,
  isMarketingPage,
  interactuarHeight,
  ...rest
}) => {
  const gContext = useContext(GlobalContext)

  const getSrcLogo = () => {
    if (isInteractuar && isMobile) {
      return imgInteractuarLogo
    }
    if (isInteractuar && gContext.isMobile) {
      return imgInteractuarByFactcilMobileLogo
    }
    if (isInteractuar) {
      return imgInteractuarByFactcilLogo
    }
    if (isMobile) {
      return imgLogoMobile
    }
    return imgL1LogoWhite
  }

  const getHeightLogo = () => {
    if (isInteractuar) {
      return interactuarHeight
    }
    return height
  }

  const getWhiteLogo = () => {
    return isMarketingPage ? imgFactcilMarketingLogo : imgL1LogoWhite
  }

  return (
    <Link
      to={`${isInteractuar ? "/interactuar" : isMarketingPage ? "/comienza-ahora" : "/"
        }`}
      className={`${className}`}
      {...rest}
    >
      {white ? (
        <img
          src={isMobile ? imgLogoMobile : getWhiteLogo()}
          alt=""
          css={`
            height: ${height ? height : "25px"};
          `}
        />
      ) : (
        <img
          src={getSrcLogo()}
          alt=""
          css={`
            ${typeof getHeightLogo() == "object"
              ? `
              height: ${getHeightLogo() ? getHeightLogo()[0] : "32px"};
              @media ${device.sm} {
                height: ${getHeightLogo()[0] || getHeightLogo()};
              }
              @media ${device.md} {
                height: ${getHeightLogo()[1] || getHeightLogo()[0] || getHeightLogo()
              };
              }
              @media ${device.lg} {
                height: ${getHeightLogo()[2] ||
              getHeightLogo()[1] ||
              getHeightLogo()[0] ||
              getHeightLogo()
              };
              }
              @media ${device.xl} {
                height: ${getHeightLogo()[3] ||
              getHeightLogo()[2] ||
              getHeightLogo()[1] ||
              getHeightLogo()[0] ||
              getHeightLogo()
              };
              }`
              : `
              height: ${getHeightLogo() ? getHeightLogo() : "32px"};
              `}
            ${noLeftPadding &&
            `
              padding-left: 0px;
              @media ${device.sm} {
                padding-left: 20px;
              }
              `}
            ${isHeader &&
            `@media ${device.xl} {
              padding-left: 30%;
              }
              @media ${device.xs} {
                padding-left: 0%;
              `}
          `}
        />
      )}
    </Link>
  )
}

export default Logo
